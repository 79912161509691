/* eslint-disable no-unused-expressions */
import React from "react"
import { graphql } from "gatsby"

import Section from "../components/wwc/layout/Section/Section"
import Row from "../components/wwc/layout/Row/Row"
import Column from "../components/wwc/layout/Column/Column"

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    this.loadLegalsPage("", "#legals")
  }

  loadLegalsPage(url, selector) {
    var container = document.querySelectorAll(selector)
    if (container && container.length > 0) {
      this.loadCentralizedLegalPage(url, container)
    } else {
      document.addEventListener("DOMContentLoaded", function(event) {
        container = document.querySelectorAll(selector)
        if (container && container.length > 0) {
          this.loadCentralizedLegalPage(url, container)
        }
      })
    }
  }

  loadCentralizedLegalPage(url, container) {
    var xhr = new XMLHttpRequest()
    var doc, legals
    var _url = url || "https://www.wonderful.com/privacy.html"
    xhr.open("GET", _url, true)
    xhr.onreadystatechange = function() {
      if (this.readyState !== 4) return
      if (this.status !== 200) {
        container[0].innerHTML =
          '<div><h1>Privacy Policy</h1><p>Something went wrong but you can stil see our Privacy Policy below <a rel="noreferrer noopener" target="_blank" style="text-decoration:underline" href="' +
          _url +
          '">' +
          _url +
          "</a></p></div>"
      }
      doc = new DOMParser().parseFromString(this.responseText, "text/html")
      legals = doc.querySelectorAll(".legal")
      if (legals.length > 0) {
        container[0].innerHTML = legals[0].innerHTML
      }
    }
    xhr.send()
  }

  render() {
    return (
      <>
        <Section id="privacy-policy" className="section__legal">
          <Row>
            <Column>
              <div id="legals"></div>
            </Column>
          </Row>
        </Section>
      </>
    )
  }
}

export const query = graphql`
  query PrivacyPolicy($locale: String) {
    siteJson(locale: { eq: $locale }) {
      ...SiteData
    }
    privacyPolicyJson(locale: { eq: $locale }) {
      content {
        text
      }
    }
  }
`

export default PrivacyPolicy
